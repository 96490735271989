@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Monument Extended";
  src: local("Monument Extended"),
    url("./assets/fonts/MonumentExtended-FreeForPersonalUse/MonumentExtended-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #000000;
}

h1,
h2 {
  font-family: "Monument Extended";
  text-shadow: 0px 4px 4px #0500FF;
}

p {
  font-family: 'Roboto';
  font-style: normal;
}

#section-1 {
  background-image: url("./assets/images/section-1.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
}


.blur-ball-left {
  position: absolute;
  width: 1203px;
  height: 1203px;
  left: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}

.blur-ball-right {
  position: absolute;
  width: 1203px;
  height: 1203px;
  right: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}